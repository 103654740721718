//created by Peggy on 2021/6/21
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Select,
  Tooltip,
} from "antd";
import HomeLayout from "@/components/layouts/HomeLayout";

import StripePayCom from "@/components/StripePayment";
import Modal from "@/components/Modal/Info";
import { useModel } from "use-reaction";
import { user } from "@/model/user";

import {
  consultPrice,
  consultOrder,
  consultByStripe,
  couponCheck,
} from "@/api/consultation-api";

import { ReactComponent as CreditCard } from "@/images/credit_card.svg";
import icon_grant from "@/images/icons/icon_grant.svg";

import "@/styles/home/checkout.less";
import styled from "styled-components";
import { stateList } from "@/types/enum";
import { InfoCircleFilled } from "@ant-design/icons";
import { TheField } from "@/components/GoogleTranslate";
import IsThisCorrect from "../donation/IsThisIncorrect";
const ConsultationContainer = styled.div``;
const { getData: getCountryList } = require('country-list');
const countryList = getCountryList()

const typeNums: any = {
  "consultation-artist": {
    label: "Consultation",
    description: "Continue to book your consultation with The Field",
    type: "consultation",
  },
  "misc-fee": {
    label: "Misc Fee ",
    description: "Continue to pay your fee",
    type: "misc_fee",
  },
  "misc-fee-nosign": {
    label: "Misc Fee ",
    description: "Continue to pay your fee",
    type: "misc_fee",
  },
  "carryover-fee": {
    label: "Carryover fees",
    description: "You’re purchasing",
    type: "carryover_fee",
  },
  "expedite-disbursement-fee": {
    label: "Expedite Disbursement fees",
    description: "You’re purchasing",
    type: "expedite_disbursement_fee",
  },
};

const StripePay = React.forwardRef<any, any>((props, ref) => (
  <StripePayCom {...props} submitRef={ref} />
));

const Consultation = () => {
  const history = useHistory();
  const { type } = useParams() as any;
  const submitRef = useRef<any>(null);
  const [form] = Form.useForm();
  const {
    store: { token, info },
  } = useModel(user);
  const miscfeeNoSign = type === 'misc-fee-nosign' // see router in homepage.ts
  const [secretId, setSecretId] = useState<any>();
  const [flag, setFlag] = useState(false);

  const [paying, setPaying] = useState(false);

  const [pay, setPay] = useState<any>({
    payment: "credit card",
    address: "",
    name:info.firstName,
    email:info.email,
    city: "",
    state: "",
    country: "US",
    code: "",
  });
  const [price, setPrice] = useState<any>();
  const [order, setOrder] = useState<any>();
  const [amount, setAmount] = useState(0);
  const [note, setNote] = useState('')

  const [cusAmount, setCusAmount] = useState<number>(0);
  const [addon, setAddon] = useState<any>({
    fieldAmount: "",
    contribution: false,
  });
  const [promo, setPromo] = useState<any>({
    name: "",
    discount: 0,
    error: false,
  });
  const couponValidate = useCallback(
    (name: string, price?: string | number) => {
      if (!name) {
        setPromo({ name, discount: 0 });
        return false;
      }
      if (!order?.price) {
        return false;
      }
      couponCheck({
        name,
        productType: typeNums[type].type, //consultation
        price: price || order?.price,
      })
        .then((data) =>
          setPromo({ name, discount: data?.discount, error: false })
        )
        .catch((e) => {
          setPromo({
            name: "",
            discount: 0,
            error: true,
          });
        });
    },
    [promo?.name, order?.price]
  );

  useEffect(() => {
    setAmount(
      parseFloat(addon.fieldAmount || "0") + parseFloat(order?.price || "0")
    );
  }, [addon.fieldAmount, order?.price]);

  const onFinish = async ({ lastName, ...values }: any) => {
    let _data: any;

    if (type.includes("misc-fee")) {
      let _temp = [];
      if (!order?.price) {
        _temp.push("amount");
      }
      if(!note){
        _temp.push('note')
      }

      setRequired(_temp);

      if (order?.price - 0 < 1) {
        message.error("Set minimum amount = $1");
        return false;
      }
      if(_temp.includes('note')){
        message.error("Please input notes")
        return false;
      }
      setPaying(true);
      _data = await consultOrder(order?.product, {
        price: order?.price,
        ...(addon.fieldAmount || note
          ? {
            lastName,
            name: values.name,
            email: values.email,
            fieldAmount: addon.fieldAmount || '0',
            message: note
          }
          : {}),
      });
      await setPrice(_data);
    } else {
      setPaying(true);
      _data = await consultOrder(
        order?.product,
        addon.fieldAmount
          ? {
            lastName,
            name: values.name,
            email: values.email,
            fieldAmount: addon.fieldAmount,
          }
          : undefined
      );
      await setPrice(_data);
    }

    await consultByStripe({ orderId: _data?.id, coupon: promo.name }).then(
      async (data: any) => {
        if(data?.noNeedToPay){
          history.push(`/checkout/success`);
          return
        }
        setSecretId(data?.client_secret);
        await setPay({ ...pay, ...values, name: `${values.name} ${lastName}` });
        await submitRef?.current?.submit();
      }
    );
  };

  const onFinishFailed = (e: any) => {
    console.log("Failed:", e);
    message.error(e.errorFields[0].errors[0]);
  };
  useEffect(() => {
    if (!token && !miscfeeNoSign) {
      setFlag(true);
    } else {
      consultPrice({
        type: typeNums[type].type,
      }).then((data: any) => {
        setOrder(data);
      });
    }
    form.setFieldsValue({ country: 'US' })
  }, []);

  //misc
  const [required, setRequired] = useState<any[]>([]);

  return (
    <HomeLayout style={{ backgroundColor: "#f1f1f6" }}>
      <ConsultationContainer className="consultation-container">
        <h1>Check out - {typeNums[type].label}</h1>
        <h2>{typeNums[type].description}</h2>
        <div className="flex align-start">
          <div className="card-wrap left-wrap">
            <div className="flex column start align-start">
              {type.includes("misc-fee") && (
                <>
                  <h2>Fee Amount </h2>
                  <div
                    className={`field-form-items required ${required.includes("amount") ? "field-error-item" : ""
                      }`}
                  >
                    <span>Custom Amount</span>
                    <Input
                      size="large"
                      placeholder="Enter custom amount"
                      prefix="$"
                      maxLength={8}
                      type="number"
                      className="input-num-no-stepper"
                      value={cusAmount || ""}
                      onBlur={(e) => {
                        const _temp = JSON.parse(JSON.stringify(required));
                        _temp.splice(
                          _temp.findIndex((item: any) => item === "amount"),
                          1
                        );
                        setRequired(_temp);
                        const price = +parseFloat(e.target.value).toFixed(2)
                        setOrder({
                          ...order,
                          price,
                        });
                        couponValidate(promo?.name, price);
                      }}
                      onChange={(e) => {
                        setCusAmount(
                          +parseFloat(e.target.value).toFixed(2)
                        );
                      }}
                    />
                  </div>
                  <br/>
                  <div className={`field-form-items required ${required.includes("note") ? "field-error-item" : ""
                    }`}>
                    <span>Notes</span>
                    <Input value={note} onChange={e=> setNote(e.target.value)} placeholder="Please indicate what this fee is for" />
                  </div>
                  <Divider />
                </>
              )}
              <h2>Payment method</h2>
              <Radio.Group
                defaultValue="credit card"
                className="card-radio"
                size="large"
                onChange={(e) => {
                  setPay({
                    ...pay,
                    payment: e.target.value,
                  });
                }}
              >
                <Radio.Button value="credit card">
                  <CreditCard />
                  Credit card
                </Radio.Button>
              </Radio.Group>
            </div>
            <Divider />
            <h2>Credit card information</h2>
            <StripePay
              secretId={secretId}
              payId={price?.id}
              payInfo={{ ...pay }}
              setPaying={setPaying}
              ref={submitRef}
              showRequired={miscfeeNoSign}
              onNext={() => {
                setPaying(false);
                history.push(`/checkout/success`);
              }}
            />
            <Divider />
            <h2>Billing information </h2>
            <IsThisCorrect isStep2/>
            <Form
              layout="vertical"
              size="large"
              name="basic"
              form={form}
              initialValues={{
                ...pay,
                name:info.firstName,
                lastName:info.lastName,
                email:info.email
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onValuesChange={(val) => {
                if (val.country) {
                  form.setFieldsValue({ state: '', code:'' })
                }
              }}
            >
              <Form.Item
                label="First Name"
                name="name"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Enter first Name",
                  },
                ]}
              >
                <Input disabled={!!token} size="large" placeholder="Enter first Name" />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Enter last Name",
                  },
                ]}
              >
                <Input disabled={!!token} size="large" placeholder="Enter last Name" />
              </Form.Item>
              <Form.Item
                label="Email address"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Enter Email address",
                  },
                ]}
              >
                <Input disabled={!!token} size="large" placeholder="Enter Email address" />
              </Form.Item>
              <Form.Item
                label="Billing Address"
                name="address"
                rules={[
                  {
                    required: true,
                    whitespace: false,
                    message: "Enter billing address",
                  },
                ]}
              >
                <Input size="large" placeholder="Address" />
              </Form.Item>
              <Form.Item
                label="Country"
                name="country"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Choose Country",
                  },
                ]}
              >
                <Select
                  showSearch
                  size="large"
                  placeholder="Country"
                  optionFilterProp="label"
                  options={countryList.map((item:any) => ({
                    value:item.code,label:item.name
                  }))}
                />
              </Form.Item>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Enter city",
                  },
                ]}
              >
                <Input size="large" placeholder="City" />
              </Form.Item>
              <div className="flex start align-start row-flex">
                <Form.Item noStyle dependencies={['country']}>
                  {
                    ({ getFieldValue }) => {
                      return getFieldValue('country') === 'US' ?
                        <Form.Item
                          label="State"
                          name="state"
                          className="select-item"
                          rules={[
                            {
                              required: true,
                              whitespace: false,
                              message: "Select State",
                            },
                          ]}
                        >

                          <Select
                            showSearch
                            size="large"
                            placeholder="State"
                          >
                            {Object.values(stateList)?.map((item) => (
                              <Select.Option value={item} key={item}>
                                {item}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item> :
                        <Form.Item
                          label="State/Province"
                          name="state"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Enter State",
                            },
                          ]}
                        >
                          <Input size="large" placeholder="State" />
                        </Form.Item>
                    }
                  }
                </Form.Item>
                <Form.Item noStyle dependencies={["country"]}>
                  {({ getFieldValue }) => {
                    return getFieldValue("country") === "US" ? (
                      <Form.Item
                      label="Zip Code/Postal Code"
                      name="code"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Please enter your 5-digit zip code.',
                          validator: (_, value) => {
                            if(/(^\d{5}$)/.test(value)){
                              return Promise.resolve()
                            }else{
                              return Promise.reject()
                            }
                          }
                        },
                      ]}
                    >
                      <Input style={{width:'100%'}} onBeforeInput={(e:any) => {
                          if (!/^\d*$/.test(e.data)) {
                            e.preventDefault();
                          }
                        }} maxLength={5} placeholder="Zip Code" />
                    </Form.Item>
                    ) : (
                      <Form.Item
                      label="Zip Code/Postal Code"
                      name="code"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Please enter your zip code.'
                        },
                      ]}
                    >
                      <Input maxLength={10} placeholder="Zip Code" />
                    </Form.Item>
                    );
                  }}
                </Form.Item>
              </div>
            </Form>
            <Divider />
            <div className="flex column start align-start">
              <h2>
                Contribute to <TheField/>
                <Tooltip title="Contributions to The Field help us serve and support independent performing artists and their companies. Thank you!">
                  <InfoCircleFilled
                    style={{ fontSize: 16, color: "#696969", marginLeft: 4 }}
                  />
                </Tooltip>
              </h2>
              <Radio.Group
                value={addon.contribution}
                className="frequency-radio"
                size="large"
                onChange={(e) => {
                  setAddon({
                    ...addon,
                    fieldAmount: "",
                    contribution: e.target.value,
                  });
                }}
              >
                <Radio.Button value={false}>No, thanks</Radio.Button>
                <Radio.Button value={true}>Yes, I’d love to</Radio.Button>
              </Radio.Group>

              {addon.contribution && (
                <div className="field-form-items">
                  <span>Amount </span>
                  <Input
                    size="large"
                    placeholder="Enter amount"
                    prefix="$"
                    className="input-num-no-stepper"
                    type='number'
                    value={addon.fieldAmount}
                    onChange={(e) => {
                      const fieldAmount = +parseFloat(e.target.value).toFixed(2);
                      setAddon({
                        ...addon,
                        fieldAmount,
                      });
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="card-wrap right-wrap">
            <h3>Order summary</h3>
            <p className="flex">
              <span>Product</span>
              {/* Consultation */}
              {typeNums[type].label}
            </p>
            <p className="flex">
              <span>Price</span>
              {order?.price?.toLocaleString("en", {
                style: "currency",
                currency: "USD",
              })}
            </p>
            {addon.fieldAmount && (
              <p className="flex">
                <span>Contribute to <TheField/></span>
                {addon?.fieldAmount?.toLocaleString("en", {
                  style: "currency",
                  currency: "USD",
                })}
              </p>
            )}
            <p className="flex">
              <span>Billing cycle</span>
              {price?.interval === "once" ? "One time" : ""}
            </p>
            <p className="flex">
              <span>Next billing date</span>
              {"N/A"}
            </p>
            <Divider />

            <p className="flex">
              Discount
              <b>
                {Number(promo?.discount || 0).toLocaleString("en", {
                  style: "currency",
                  currency: "USD",
                })}
              </b>
            </p>
            <p className="flex">
              Total amount
              <b>
                {Number(
                  amount - promo?.discount >= 0 ? amount - promo?.discount : 0
                ).toLocaleString("en", {
                  style: "currency",
                  currency: "USD",
                })}
              </b>
            </p>
            <Divider />
            <div className="flex align-start" style={{ width: "100%" }}>
              <div style={{ flex: "0 0 calc(100% - 120px)" }}>
                <div
                  className={`field-form-items ${promo?.error ? "field-error-item" : ""
                    }`}
                >
                  <span>PROMO CODE</span>
                  <Input
                    allowClear
                    placeholder="Promo code..."
                    onChange={(e) =>
                      setPromo({
                        ...promo,
                        name: e?.target.value,
                        error: false,
                      })
                    }
                  />
                </div>
                <p className="error-text">
                  Invalid code, please try another one
                </p>
              </div>
              <Button
                style={{ flex: "0 0 100px", height: "56px", marginLeft: 20 }}
                onClick={() => couponValidate(promo.name)}
              >
                Apply
              </Button>
            </div>

            <Divider />
            <p className="tips">
              By confirming this payment, you agree to our{" "}
              <a
                rel="noreferrer"
                target="_blank"
                href="https://thefield.org/terms-of-service/"
              >
                TERMS OF SERVICE
              </a>{" "}
              {/*To ensurea continued service, we'll store and update (e.g., upon*/}
              {/*expiration) your payment method.*/}
              {/*Learn about{" "}*/}
              {/*<a*/}
              {/*  rel="noreferrer"*/}
              {/*  target="_blank"*/}
              {/*  href="https://thefield.org/terms-of-service/"*/}
              {/*>*/}
              {/*  HOW TO CANCEL*/}
              {/*</a>{" "}*/}
              {/*and our{" "}*/}
              {/*<a*/}
              {/*  rel="noreferrer"*/}
              {/*  target="_blank"*/}
              {/*  href="https://thefield.org/terms-of-service/"*/}
              {/*>*/}
              {/*  REFUND POLICY*/}
              {/*</a>*/}
              {/*. Prices may change.*/}
            </p>

            <Button
              disabled={paying}
              type="primary"
              block
              onClick={() => form.submit()}
            >
              confirm payment
            </Button>
          </div>
        </div>
      </ConsultationContainer>
      <Modal
        visible={flag}
        width={420}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => history.push(`/sign/in?to=/checkout/${type}`)}
          >
            Sign in
          </Button>,
        ]}
        img={icon_grant}
      >
        <h2>Sign in to check out</h2>
        <p>
          Please log in to complete this order.
        </p>
      </Modal>
    </HomeLayout>
  );
};

export default Consultation;
